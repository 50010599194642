import { Box, Card, CardHeader, TextField, useTheme } from "@mui/material";
import { merge } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DefaultChartOptions from "../../components/DefaultChartOptions";
import { loadDashboardStats } from "../../redux/slices/dashboardSlice";
import { useDispatch } from "react-redux";

function BarGraph({ callsData }) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const handleChangeSeriesData = (event) => {
    dispatch(loadDashboardStats({ filter: event.target.value }))
  };

  const CHART_DATA = [
    { name: "Picked", data: callsData.Picked },
    { name: "Dropped", data: callsData.Dropped },
  ];

  const chartOptions = merge(DefaultChartOptions(), {
    stroke: {
      show: true,
      width: 3,
      colors: ["transparent"],
    },
    xaxis: {
      categories: callsData.xaxis.categories,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} calls`,
      },
    },
    colors: [
      theme.palette.chart.green[0],
      theme.palette.chart.red[0]
    ],
  });

  useEffect(() => {
    console.log(callsData)
  }, [])

  return (
    <Card>
      <CardHeader
        title="Calls"
        subheader="Your calls data"
        action={
          <TextField
            select
            fullWidth
            SelectProps={{ native: true }}
            onChange={handleChangeSeriesData}
            sx={{
              "& fieldset": { border: "0 !important" },
              "& select": {
                pl: 1,
                py: 0.5,
                pr: "24px !important",
                typography: "subtitle2",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: 0.75,
                bgcolor: "background.neutral",
              },
              "& .MuiNativeSelect-icon": {
                top: 4,
                right: 0,
                width: 20,
                height: 20,
              },
            }}
          >
            <option value={'last 24 hours'}>
              Last 24 hours
            </option>
            <option value={'last 7 days'} selected={true}>
              Last 7 days
            </option>
            <option value={'last month'}>
              Last Month
            </option>
            <option value={'this year'}>
              This year
            </option>
          </TextField>
        }
      />

      <Box sx={{ mt: 3, mx: 3 }}>
        <ReactApexChart
          type="bar"
          series={CHART_DATA}
          options={chartOptions}
          height={360}
        />
      </Box>
    </Card>
  );
}

export default BarGraph;

import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    loadAllCalls,
    selectPage,
    selectPagesCount,
    selectAllCalls,
    getSingleCalls,
    selectSize,
    updateSelectedCall,
    updateOpenEditCallNotesDialog,
    selectSelectedCall,
    selectOpenEditCallNotesDialog,
    updateCallNotes
} from "../../redux/slices/callSlice";
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Button } from '@mui/material';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

export default function EditCallNotesDialog() {
    const selectedCall = useSelector(selectSelectedCall)
    const open = useSelector(selectOpenEditCallNotesDialog)
    const [callNotes, setCallNotes] = useState('')
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(updateOpenEditCallNotesDialog(false));
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='lg'
            onClose={handleClose}
        >
            <DialogTitle>
                {"Call Notes"}
            </DialogTitle>
            <DialogContent>
                <Box>
                    {
                        <SunEditor
                            height="50vh"
                            defaultValue={selectedCall?.call_notes}
                            value={selectedCall?.call_notes}
                            setDefaultStyle="font-family: Public Sans, sans-serif; font-size: 16px;"
                            onChange={(value) => { setCallNotes(value) }}
                            setOptions={{
                                buttonList: [
                                    ['undo', 'redo'],
                                    ['fontSize', 'formatBlock'],
                                    ['paragraphStyle', 'blockquote'],
                                    ['bold', 'underline', 'italic'],
                                    ['fontColor', 'hiliteColor'],
                                    ['image']
                                ]
                            }}
                        />
                    }
                </Box>

                <Box mt={2}>
                    <Button
                        size='large'
                        onClick={() => { handleClose(); dispatch(updateCallNotes({ notes: callNotes })); }}
                        variant="contained"
                    >
                        Save
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

import { createStyles, makeStyles, useTheme } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      ".apexcharts-legend": {
        padding: "0 !important",
      },
      ".apexcharts-legend-series": {
        alignItems: "center",
        display: "flex !important",
      },
      ".apexcharts-legend-marker": {
        marginRight: "8px !important",
        marginTop: "-2px !important",
      },
      ".apexcharts-legend-text": {
        lineHeight: "18px",
        textTransform: "capitalize",
      },
      ".apexcharts-tooltip,.apexcharts-xaxistooltip": {
        border: "0 !important",
        boxShadow: `${theme.customShadows.z24} !important`,
        color: `${theme.palette.text.primary} !important`,
        borderRadius: `${theme.shape.borderRadiusSm}px !important`,
        backgroundColor: `${theme.palette.background.default} !important`,
      },
      ".apexcharts-tooltip-title": {
        border: "0 !important",
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: `${theme.palette.grey[500_16]} !important`,
        color:
          theme.palette.text[
          theme.palette.mode === "light" ? "secondary" : "primary"
          ],
      },
      ".apexcharts-xaxistooltip-bottom": {
        "&:before": {
          borderBottomColor: "transparent !important",
        },
        "&:after": {
          borderBottomColor: `${theme.palette.background.paper} !important`,
        },
      },
    },
  })
);

function DefaultChartOptions() {
  useStyles();
  const theme = useTheme();

  const LABEL_TOTAL = {
    show: true,
    label: "Total",
    color: theme.palette.text.secondary,
    ...theme.typography.subtitle2,
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    ...theme.typography.h3,
  };

  return {
    colors: [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.chart.violet[0],
      theme.palette.chart.green[0],
      theme.palette.chart.red[0],
      theme.palette.chart.yellow[0],
      theme.palette.chart.blue[0],
    ],
    chart: {
      toolbar: { show: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily,
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.04,
        },
      },
      active: {
        filter: {
          type: "darken",
          value: 0.88,
        },
      },
    },
    fill: {
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider,
    },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper,
    },
    dataLabels: { enabled: false },
    stroke: {
      width: 3,
      curve: "smooth",
      lineCap: "round",
    },
    tooltip: {
      x: {
        show: false,
      },
    },
    legend: {
      show: true,
      fontSize: 13,
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 12,
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: theme.palette.text.primary,
      },
    },
    plotOptions: {
      // Bar
      bar: {
        columnWidth: "28%",
        borderRadius: 4,
      },
      // Pie + Donut
      pie: {
        donut: {
          labels: {
            show: true,
            value: LABEL_VALUE,
            total: LABEL_TOTAL,
          },
        },
      },
      // Radialbar
      radialBar: {
        track: {
          strokeWidth: "100%",
          background: theme.palette.grey[500_16],
        },
        dataLabels: {
          value: LABEL_VALUE,
          total: LABEL_TOTAL,
        },
      },
      // Radar
      radar: {
        polygons: {
          fill: { colors: ["transparent"] },
          strokeColors: theme.palette.divider,
          connectorColors: theme.palette.divider,
        },
      },
      // polarArea
      polarArea: {
        rings: {
          strokeColor: theme.palette.divider,
        },
        spokes: {
          connectorColors: theme.palette.divider,
        },
      },
    },
    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: { bar: { columnWidth: "40%" } },
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: { bar: { columnWidth: "32%" } },
        },
      },
    ],
  };
}

export default DefaultChartOptions;

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FlightIcon from '@mui/icons-material/Flight';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarGraph from "../components/Dashboardpage/BarGraph";
import WeeklyInstalls from "../components/Dashboardpage/WeeklyInstalls";
import { selectDashboardStats, loadDashboardStats, selectCallsData } from "../redux/slices/dashboardSlice";

export default function Dashboardpage() {
  const stats = useSelector(selectDashboardStats)
  const callsData = useSelector(selectCallsData)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadDashboardStats({ filter: 'last 7 days' }))
  }, [])

  return (
    <Grid
      container
      spacing={3}
      justifyContent={{ xs: "center", md: "flex-start" }}
    >
      <Grid item xs={12} md={12}>
        <WeeklyInstalls
          icon={
            <LocalPhoneIcon height={24} width={24} />
          }
          value={stats?.total_calls}
          label={'Total Calls'}
        />
      </Grid>

      {/* Calls */}
      <Grid item xs={12} md={12}>
        {
          callsData != null && <BarGraph callsData={callsData} />
        }
      </Grid>
    </Grid>
  );
}

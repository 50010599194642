import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card, Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllCalls,
  selectPage,
  selectPagesCount,
  selectAllCalls,
  getSingleCalls,
  selectSize,
  updateSelectedCall,
  updateOpenEditCallNotesDialog,
  updateOpenSessionLogsDialog
} from "../../redux/slices/callSlice";
import { formatTime } from '../../utils/formatTime';
import Label from "../Label";
import { useMemo } from "react";
import { staticBaseURL } from '../../utils/backendServerBaseURL';
import { Link } from "@mui/material";

function CallsTable() {
  const allCalls = useSelector(selectAllCalls)
  const page = useSelector(selectPage)
  const size = useSelector(selectSize)
  const pagesCount = useSelector(selectPagesCount)

  const dispatch = useDispatch()

  const theme = useTheme();

  useEffect(() => {
    dispatch(loadAllCalls({ page: 1 }))
    // dispatch(getSingleCalls({ id: '62662154b21c7fcecab50e1d' }))
  }, [])

  const formatDuration = (duration) => {
    if ((duration / (1000 * 60)).toString() !== 'NaN') {
      if (duration / (1000 * 60) > 1) {
        return (Math.round((duration / (1000 * 60)) * 100) / 100) + ' Min'
      } else {
        return (Math.round((duration / (1000)) * 100) / 100) + ' Sec'
      }
    } else {
      return '0 sec'
    }
  }

  return (
    <>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Download Recording</TableCell>
                <TableCell>Call Notes</TableCell>
                <TableCell>Session Logs</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {allCalls.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell sx={{ textTransform: 'capitalize' }}>{row.type}</TableCell>
                  <TableCell>{formatTime(row.createdAt)}</TableCell>
                  <TableCell sx={{ textTransform: 'capitalize' }}>{row.status}</TableCell>
                  <TableCell sx={{ textTransform: 'capitalize' }}>{formatDuration(row.duration)}</TableCell>
                  <TableCell sx={{ textTransform: 'capitalize' }}>
                    <a href={staticBaseURL + '/' + row.recordingFileName} target='_blank'>Download</a>
                  </TableCell>

                  <TableCell sx={{ textTransform: 'capitalize' }}>
                    <Button variant='contained' onClick={() => { dispatch(updateSelectedCall(row)); dispatch(updateOpenEditCallNotesDialog(true)) }}>View/Update Call Notes</Button>
                  </TableCell>

                  <TableCell sx={{ textTransform: 'capitalize' }}>
                    {
                      Object.keys(row).includes('log') && <Button variant='contained' onClick={() => {
                        dispatch(updateSelectedCall(row));
                        dispatch(updateOpenSessionLogsDialog(true))
                      }}>View session logs</Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />

        {
          pagesCount > 1 && <Box p={2}>
            <Pagination count={pagesCount} onChange={(e, page) => { dispatch(loadAllCalls({ page })) }} color="primary" />
          </Box>
        }
      </Card>
    </>
  );
}

export default CallsTable;

import { Card, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const WrapperStyle = styled("div")(({ theme }) => ({
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(130deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(4),
  height: theme.spacing(4),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

function WeeklyInstalls({ icon, value, label }) {
  return (
    <RootStyle>
      <Typography variant="h1">{value}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {label}
      </Typography>
    </RootStyle>
  );
}

export default WeeklyInstalls;

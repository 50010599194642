import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectCallId, selectFrom, selectSessionId, selectSessionLogs, selectSignal } from '../redux/slices/videoCallSlice';

export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let dispatch = useDispatch();

        let callId = useSelector(selectCallId);
        let from = useSelector(selectFrom);
        let sessionId = useSelector(selectSessionId);
        let sessionLogs = useSelector(selectSessionLogs);
        let signal = useSelector(selectSignal);

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
                redux={{ dispatch, callId, sessionLogs, sessionId, signal, from }}
            />
        );
    }
    return ComponentWithRouterProp;
}
import { alpha, styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const RootStyle = styled("span")(({ theme, styleprops }) => {
  const { color, variant } = styleprops;

  const styleFilled = (color) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,
  });

  const styleGhost = (color) => ({
    color: theme.palette[color]["dark"],
    backgroundColor: alpha(theme.palette[color].main, 0.16),
  });

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 8,
    padding: theme.spacing(0, 1),
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,
    ...(color !== "default"
      ? {
        ...(variant === "opaque" && { ...styleFilled(color) }),
        ...(variant === "filled" && { ...styleGhost(color) }),
      }
      : {
        ...(variant === "filled" && {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.grey[500_16],
        }),
      }),
  };
});

export default function Label({
  color,
  variant = "filled",
  children,
  ...other
}) {
  return (
    <RootStyle styleprops={{ color, variant }} {...other}>
      {children}
    </RootStyle>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ]),
  variant: PropTypes.oneOf(["opaque", "filled"]),
};

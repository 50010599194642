import { Logout } from "@mui/icons-material";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CallIcon from '@mui/icons-material/Call';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar as MuiAppBar,
  Avatar,
  Box, Card, Divider,
  Drawer as MuiDrawer, Fab, IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack, Switch, Toolbar,
  Typography, useMediaQuery
} from "@mui/material";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { alpha, styled, useTheme } from "@mui/system";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import ReactNotifications from 'react-browser-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { appendReceivingCall, removeReceivingCall, updateCallId, updateFrom, updateSessionId, updateSessionLogs, updateSignal } from '../redux/slices/videoCallSlice';
import { ThemeContext } from "../theme";
import { selectReceivingCalls } from "../redux/slices/videoCallSlice";
import { currentVersion } from "../utils/version";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)})`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",

  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(open === false && {
    marginLeft: drawerWidth,
    width: `calc(100% - (calc(${theme.spacing(7)} + 24px)))`,
  }),
}));

const MobileAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  color: "black",
  paddingTop: "0.5rem",
}));

const MobileRootStyle = styled(MobileAppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  color: "black",
  paddingTop: "0.5rem",
  paddingRight: '1rem'
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default function Dashboard({ socket }) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userdata, _] = React.useState(localStorage.getItem("user"));
  const dispatch = useDispatch()
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [currentMenuId, updateCurrentMenuId] = React.useState(0);
  const [fixedOpen, setFixedOpen] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const { isLight, setIsLight } = useContext(ThemeContext);

  const menuConfig = [
    {
      id: 0,
      text: "Dashboard",
      link: "/dashboard/dashboardpage",
      icon: <DashboardIcon />,
    },

    {
      id: 1,
      text: "Calls",
      link: "/dashboard/calls",
      icon: <AdminPanelSettingsIcon />,
    }
  ];

  const handleDrawerClose = () => {
    if (fixedOpen === true) {
      setOpen(false);
    }

    setFixedOpen(!fixedOpen);
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    navigate("/");
  };

  const ListItemStyle = styled((props) => (
    <ListItemButton disableGutters {...props} />
  ))(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: "relative",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 8,
    color: theme.palette.text.secondary,
    "&:before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: "none",
      position: "absolute",
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  }));

  const ListItemIconStyle = styled(ListItemIcon)({
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const ListStyle = styled(List)({
    paddingRight: 14,
    paddingLeft: 14,
  });

  useEffect(() => {
    console.log("Location changed");
    console.log(location);
    console.log(menuConfig);

    for (let i = 0; i < menuConfig.length; i++) {
      if (menuConfig[i].link === location.pathname) {
        console.log("AAAAAAA");
        updateCurrentMenuId(menuConfig[i].id);
      }
    }
  }, [location]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // ------------------------------
  // Video call integration
  // ------------------------------
  const receivingCalls = useSelector(selectReceivingCalls)

  const initiateVideoCallAnswering = (data) => {
    dispatch(updateFrom(data.from))
    dispatch(updateSignal(data.signal))
    dispatch(updateCallId(data.callId))
    dispatch(updateSessionLogs(data.sessionLogs))
    dispatch(updateSessionId(data.sessionId))

    navigate('/video-call')
  }

  const cancelCall = (targetCall) => {
    dispatch(removeReceivingCall({ data: targetCall }))
  }



  return (
    <>
      <Box sx={{ display: { 'xs': 'block', 'md': 'flex' } }}>
        {useMediaQuery('(min-width:961px)') && <>
          {/* Desktop */}
          <RootStyle sx={{ display: { 'xs': 'none', 'md': 'block' } }} position="fixed" open={open}>
            <Toolbar>
              <Typography
                variant="h5"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {menuConfig[currentMenuId].text}
              </Typography>

              <Box sx={{ flexGrow: 0 }}>
                {/* Light mode or Dark mode */}
                {/* <FormControlLabel
                control={
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    defaultChecked={!isLight}
                    onChange={(e, value) => {
                      setIsLight(!value);
                    }}
                  />
                }
                label=""
              /> */}

                {/* User menu */}
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mr: 2 }}>
                  <Avatar sx={{ bgcolor: "primary.main", width: 36, height: 36 }}>
                    <Typography variant="h6" color='common.white'>
                      {JSON.parse(userdata)?.name[0]}
                    </Typography>
                  </Avatar>
                </IconButton>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Box p={2} sx={{ minWidth: 250 }}>
                    <Typography variant="subtitle1">
                      {JSON.parse(userdata)?.name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {JSON.parse(userdata)?.email}
                    </Typography>
                  </Box>

                  <Divider></Divider>

                  <Box mt={2}>
                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Logout</ListItemText>
                    </MenuItem>
                  </Box>
                </Menu>
              </Box>
            </Toolbar>
          </RootStyle>

          <Drawer
            sx={{ display: { 'xs': 'none', 'md': 'block' } }}
            variant="permanent"
            open={open}
            onMouseEnter={() => {
              setOpen(true);
            }}
            onMouseLeave={() => {
              if (fixedOpen === false) setOpen(false);
            }}
          >
            <Toolbar>
              <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                <ListItemIcon>
                  <Box
                    component="img"
                    sx={{
                      height: 32,
                    }}
                    src="/static/logo.PNG"
                  />
                </ListItemIcon>
                {open && (
                  <>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <IconButton onClick={handleDrawerClose}>
                      {fixedOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                  </>
                )}
              </Box>
            </Toolbar>

            <Divider />

            <ListStyle>
              {menuConfig.map((menuItem, index) => (
                <ListItemStyle
                  key={index}
                  onClick={() => {
                    navigate(menuItem.link);
                  }}
                  sx={{
                    backgroundColor:
                      menuItem.id === currentMenuId && "primary.lighter",
                  }}
                >
                  <ListItemIconStyle
                    sx={{
                      color: menuItem.id === currentMenuId && "primary.main",
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIconStyle>
                  {open && (
                    <ListItemText
                      sx={{
                        paddingLeft: 0.9,
                        color: menuItem.id === currentMenuId && "primary.main",
                      }}
                      disableTypography
                      primary={menuItem.text}
                    />
                  )}
                </ListItemStyle>
              ))}
            </ListStyle>

            {/* Video call */}
            {
              open && receivingCalls.length === 0 && <Box p={2} pb={0}>
                <Card sx={{ padding: '1rem' }}>
                  <Stack direction='row' spacing={1}>
                    <CallIcon sx={{ color: 'text.secondary' }} />
                    <Typography variant="subtitle1" color='text.secondary'>Waiting for call</Typography>
                  </Stack>
                </Card>
              </Box>
            }

            {
              open && receivingCalls.length !== 0 && <Box p={2} pb={0}>
                <Card sx={{ padding: '1rem' }}>
                  <Typography variant="subtitle1" color='text.secondary'>Receiving call</Typography>
                </Card>
              </Box>
            }

            {
              open && receivingCalls.map((call, index) => {
                return <Box key={`receivingVideoCall${index}`} p={2} pb={0}>
                  <Card sx={{ padding: '1rem', backgroundColor: call.callType === 'emergency' ? 'error.lighter' : 'common.white' }}>
                    <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>{call.callType}</Typography>
                    <Typography variant="caption" color='text.secondary'>{call.from}</Typography>
                    <Stack direction='row' spacing={1} mt={2}>
                      <Fab onClick={() => { initiateVideoCallAnswering(call) }} size='small' color="success">
                        <CallIcon sx={{ color: 'white' }} />
                      </Fab>

                      <Fab onClick={() => { cancelCall(call) }} size='small' color="error" >
                        <CallIcon sx={{ color: 'white' }} />
                      </Fab>
                    </Stack>
                  </Card>
                </Box>
              })
            }

            {/* Padding */}
            <Box sx={{ flexGrow: 1 }}></Box>

            {open && (
              <Stack
                spacing={3}
                alignItems="center"
                sx={{ pb: 5, width: 1, textAlign: "center" }}
              >
                <div>
                  {/* <button onClick={showNotifications}>
                  Notify Me!
                </button> */}
                  <Typography gutterBottom variant="subtitle1">
                    Hi, {JSON.parse(userdata)?.name.split(" ")[0]}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Need help?
                    <br /> Please check our docs
                  </Typography>
                  <Typography variant='caption'>{currentVersion}</Typography>
                </div>
              </Stack>
            )}
          </Drawer>

          <Box component="main" sx={{ flexGrow: 1, p: 3, display: { 'xs': 'none', 'md': 'block' } }}>
            <DrawerHeader />

            <Outlet />
          </Box>
        </>}

        {useMediaQuery('(max-width:960px)') && <>
          {/* Mobile */}
          <MobileRootStyle position="fixed" sx={{ display: { 'xs': 'block', 'md': 'none' } }}>
            <Toolbar>
              <IconButton
                color="inherit"
                edge="end"
                onClick={() => { setOpen(true) }}
                sx={{ ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>

              <Typography
                variant="h4"
                noWrap
                component="div"
                sx={{ flexGrow: 1, marginLeft: 2 }}
              >
                {menuConfig[currentMenuId].text}
              </Typography>
            </Toolbar>
          </MobileRootStyle>

          <SwipeableDrawer
            sx={{ zIndex: 2, display: { 'xs': 'block', 'md': 'none' } }}
            anchor={'left'}
            open={open}
            onClose={() => { setOpen(false) }}
            onOpen={() => { setOpen(true) }}
          >
            <Toolbar sx={{ minWidth: '15rem' }}>
              <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                <ListItemIcon>
                  <Box
                    component="img"
                    sx={{
                      height: 32,
                    }}
                    src="/static/logo.PNG"
                  />
                </ListItemIcon>
                {open && (
                  <>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <IconButton onClick={() => { setOpen(false) }}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Toolbar>

            <Divider />

            <ListStyle>
              {menuConfig.map((menuItem, index) => (
                <ListItemStyle
                  key={index}
                  onClick={() => {
                    navigate(menuItem.link);
                    setOpen(false)
                  }}
                  sx={{
                    backgroundColor:
                      menuItem.id === currentMenuId && "primary.lighter",
                  }}
                >
                  <ListItemIconStyle
                    sx={{
                      color: menuItem.id === currentMenuId && "primary.main",
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIconStyle>
                  {open && (
                    <ListItemText
                      sx={{
                        paddingLeft: 0.9,
                        color: menuItem.id === currentMenuId && "primary.main",
                      }}
                      disableTypography
                      primary={menuItem.text}
                    />
                  )}
                </ListItemStyle>
              ))}
            </ListStyle>

            {/* Padding */}
            <Box sx={{ flexGrow: 1 }}></Box>

            {open && (
              <Stack
                spacing={3}
                alignItems="center"
                sx={{ pb: 10, width: 1, textAlign: "center" }}
              >
                <div>
                  <Typography gutterBottom variant="subtitle1">
                    Hi, {JSON.parse(userdata)?.name.split(" ")[0]}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Need help?
                    <br /> Please check our docs
                  </Typography>
                  <Typography variant='caption'>{currentVersion}</Typography>
                </div>
              </Stack>
            )}
          </SwipeableDrawer>

          <Box component="main" sx={{ flexGrow: 1, p: 3, display: { 'xs': 'block', 'md': 'none' } }}>
            <DrawerHeader />

            <Outlet />
          </Box>
        </>}
      </Box >


    </>
  );
}

import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CallsTable from "../components/Calls/CallsTable";
import EditCallNotesDialog from "../components/Calls/EditCallNotesDialog";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import SessionLogsDialog from "../components/Calls/SessionLogsDialog";


export default function Calls() {
  const dispatch = useDispatch()

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Grid item xs={12} md={12}>
          <CallsTable />
        </Grid>
      </Grid>

      <EditCallNotesDialog />
      <SessionLogsDialog />
    </>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  allCalls: [],
  selectedCall: null,

  // Dialog
  openEditCallNotesDialog: false,
  openSessionLogsDialog: false,

  // Pagination
  page: 1,
  pagesCount: 1,
  size: 20
};


export const loadAllCalls = createAsyncThunk(
  'call/loadAllCalls',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    thunkAPI.dispatch(updatePage(payload.page))

    let config = {
      params: {
        page: thunkAPI.getState().call.page,
        size: thunkAPI.getState().call.size
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/agent/calls/read`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAllCalls(response.data.payload.calls))
      thunkAPI.dispatch(updatePagesCount(Math.floor(response.data.pager.total / thunkAPI.getState().call.size)))
    }
  }
);

export const updateCallNotes = createAsyncThunk(
  'call/updateCallNotes',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/agent/calls/update/${thunkAPI.getState().call.selectedCall._id}`, {
      notes: payload.notes
    }, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllCalls({ page: thunkAPI.getState().call.page }))
      thunkAPI.dispatch(updateOpenEditCallNotesDialog(false))
    }
  }
);

export const getSingleCalls = createAsyncThunk(
  'call/getSingleCalls',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/agent/calls/read/${payload.id}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      // thunkAPI.dispatch(updateAllCalls(response.data.payload.calls))
      // thunkAPI.dispatch(updatePagesCount(response.data.pager.total))
    }
  }
);

export const callSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    updateAllCalls: (state, action) => {
      state.allCalls = action.payload;
    },
    updatePage: (state, action) => {
      state.page = action.payload;
    },
    updatePagesCount: (state, action) => {
      state.pagesCount = action.payload;
    },
    updateSelectedCall: (state, action) => {
      state.selectedCall = action.payload;
    },

    // Dialogs
    updateOpenEditCallNotesDialog: (state, action) => {
      state.openEditCallNotesDialog = action.payload;
    },
    updateOpenSessionLogsDialog: (state, action) => {
      state.openSessionLogsDialog = action.payload;
    }
  }
})

export const {
  updateAllCalls,
  updatePage,
  updatePagesCount,
  updateSelectedCall,
  updateOpenEditCallNotesDialog,
  updateOpenSessionLogsDialog
} = callSlice.actions;

export const selectAllCalls = (state) => state.call.allCalls;
export const selectPage = (state) => state.call.page;
export const selectPagesCount = (state) => state.call.pagesCount;
export const selectSize = (state) => state.call.size;
export const selectSelectedCall = (state) => state.call.selectedCall;
export const selectOpenEditCallNotesDialog = (state) => state.call.openEditCallNotesDialog;
export const selectOpenSessionLogsDialog = (state) => state.call.openSessionLogsDialog;

export default callSlice.reducer;

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box, Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { backendServerBaseURL } from "../utils/backendServerBaseURL";


const TypographyStyle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  textAlign: "center",
}));

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(false);

      await axios
        .post(`${backendServerBaseURL}/agent/auth/login`, {
          email: values["email"],
          password: values["password"],
        })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("token", response.data.payload.token);
            localStorage.setItem("user", JSON.stringify(response.data.payload.userData));
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 400) {
            setErrors({ afterSubmit: error.response.data.message });
          }
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <Grid container>
      <Box
        sx={{ minWidth: '100%' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Grid item xs={11} md={3}>
          <FormikProvider value={formik}>
            <Box
              fullWidth
              sx={{ display: "flex", justifyContent: "center" }}
              mb={2}
            >
              <Box
                component="img"
                sx={{
                  height: 40,
                  width: 40,
                }}
                src="/static/logo.webp"
              />
            </Box>

            <Typography variant="h4" textAlign="center">
              Sign in
            </Typography>
            <Typography
              variant="body1"
              mb={3}
              color="text.secondary"
              textAlign="center"
            >
              Enter your details below.
            </Typography>

            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={1} mb={3}>
                {errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit}</Alert>
                )}

                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email address"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  {...getFieldProps("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Login
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </Grid>
      </Box>
    </Grid>
  );
}

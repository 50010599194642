import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  signal: null,
  from: null,
  callId: null,
  sessionLogs: null,
  sessionId: null,
  receivingCalls: []
};

export const uploadRecording = createAsyncThunk(
  'videoCall/uploadRecording',
  async (payload, thunkAPI) => {
    console.log(payload)
    const token = await localStorage.getItem("token", "")

    var bodyFormData = new FormData();
    bodyFormData.append('file', payload.blob);
    bodyFormData.append('fileName', payload.fileName);
    bodyFormData.append('callId', payload.callId);

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/agent/calls/recording`, bodyFormData, config);

    // console.log(response.data.payload)

    // if (response.status === 200) {
    //   thunkAPI.dispatch(updateDashboardStats(response.data.payload))
    // }
  }
);

export const saveVideoCallNotes = createAsyncThunk(
  'videoCall/saveVideoCallNotes',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/agent/calls/update/${payload.callId}`, {
      call_notes: payload.notes
    }, config);

    console.log(response.data.payload)

    if (response.status === 200) {

    }
  }
);

export const videoCallSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    updateSignal: (state, action) => {
      state.signal = action.payload;
    },
    updateFrom: (state, action) => {
      state.from = action.payload;
    },
    updateCallId: (state, action) => {
      state.callId = action.payload;
    },
    updateSessionLogs: (state, action) => {
      state.sessionLogs = action.payload;
    },
    updateSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    appendReceivingCall: (state, action) => {
      const tempReceivingCall = [...state.receivingCalls]
      tempReceivingCall.push({
        signal: action.payload.data.signal,
        from: action.payload.data.from,
        callId: action.payload.data.callId,
        callType: action.payload.data.callType,
        sessionLogs: action.payload.data.sessionLogs,
        sessionId: action.payload.data.sessionId
      })

      state.receivingCalls = tempReceivingCall
    },
    removeReceivingCall: (state, action) => {
      state.receivingCalls = [...state.receivingCalls].filter(callData => callData.from != action.payload.data.from)
    },
  }
})

export const {
  updateSignal,
  updateFrom,
  updateCallId,
  updateSessionLogs,
  updateSessionId,
  appendReceivingCall,
  removeReceivingCall
} = videoCallSlice.actions;

export const selectSignal = (state) => state.videoCall.signal;
export const selectFrom = (state) => state.videoCall.from;
export const selectCallId = (state) => state.videoCall.callId;
export const selectSessionId = (state) => state.videoCall.sessionId;
export const selectSessionLogs = (state) => state.videoCall.sessionLogs;
export const selectReceivingCalls = (state) => state.videoCall.receivingCalls;

export default videoCallSlice.reducer;

import ThemeConfig from "./theme";
import Router from "./routes";
import socketHelper from './utils/socketHelper'
import { appendReceivingCall, removeReceivingCall } from "./redux/slices/videoCallSlice";
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactNotifications from 'react-browser-notifications';

let socket = new socketHelper().socket;

socket.on("me", (id) => {
  console.log('Me - ', id);
  localStorage.setItem('mySocketId', id)
});

socket.on("connect", () => {
  console.log('Socket connected')
});

// socket.on("disconnect", () => {
//   console.log('Socket disconnected')
//   window.open('/login', '_self')
// });

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    socket.on("receivingCall", (payload) => {
      // payload.signal
      // payload.from
      console.log('Receving call')
      dispatch(appendReceivingCall({ data: payload }))

      if (payload.callType === 'emergency') {
        showNotifications()
      }
    })

    socket.on("callingEnded", (data) => {
      dispatch(removeReceivingCall({ data }))
    });

    socket.on("someOtherAgentPickedTheCall", (data) => {
      dispatch(removeReceivingCall({ data }))
    });

    return () => {
      socket.off('receivingCall');
    }
  }, [])

  const showNotifications = () => {
    if (ReactNotifications.n.supported()) ReactNotifications.n.show();
  }

  const handleClick = (event) => {

  }

  return (
    <>
      {/* COMPONENTS */}
      <ThemeConfig>
        <Router socket={socket} />

        {/* Emergency call notification */}
        <ReactNotifications
          onRef={ref => (ReactNotifications.n = ref)}
          title="Emergency call alert"
          body="You have received a emergency call"
          icon="icon.png"
          tag="emergency"
          onClick={event => handleClick(event)}
        />
      </ThemeConfig>
    </>
  );
}

export default App;

import { Navigate, useRoutes } from "react-router-dom";
import Calls from "../pages/Calls";
import Dashboard from "../pages/Dashboard";
import Dashboardpage from "../pages/Dashboardpage";
import Landing from "../pages/Landing";
import Login from "../pages/Login";
import Page404 from "../pages/Page404";
import VideoCall from "../pages/VideoCall";
import { useLocation } from "react-router-dom";

export default function Router({ socket }) {
  function RequireAuth({ children }) {
    const accessToken = localStorage.getItem("token", null);
    const location = useLocation()

    if (accessToken == null || accessToken == undefined) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  }

  return useRoutes([
    {
      path: "dashboard",
      element: <RequireAuth><Dashboard socket={socket} /></RequireAuth>,
      children: [
        { path: "", element: <Dashboardpage /> },
        { path: "dashboardpage", element: <Dashboardpage /> },
        { path: "calls", element: <Calls /> },
      ],
    },

    {
      path: "/",
      element: <Login />,
      children: [],
    },

    {
      path: "/video-call",
      element: <RequireAuth><VideoCall socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "login",
      element: <Login />,
      children: [],
    },

    {
      path: "404",
      element: <Page404 />,
      children: [],
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    }
  ]);
}

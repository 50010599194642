import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './slices/dashboardSlice';
import callReducer from './slices/callSlice';
import videoCallReducer from './slices/videoCallSlice';

export const store = configureStore({
  reducer: {
    call: callReducer,
    dashboard: dashboardReducer,
    videoCall: videoCallReducer
  },
});

import CallIcon from '@mui/icons-material/Call';
import { Box, Button, Card, Fab, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { saveVideoCallNotes, uploadRecording } from '../redux/slices/videoCallSlice';
import { formatTime } from '../utils/formatTime';
import { Peerlib } from '../utils/peer';
import { withRouter } from '../utils/withRouter';
import { Component } from 'react'


class VideoCall extends Component {
    constructor(props) {
        super(props);
        this.myVideoRef = React.createRef();
        this.userVideoRef = React.createRef();
        this.notesEditorRef = React.createRef();

        // TODO: Update in withouter
        this.from = props.redux.from
        this.signal = props.redux.signal
        this.callId = props.redux.callId
        this.sessionId = props.redux.sessionId
        this.sessionLogs = props.redux.sessionLogs

        this.mySocketId = localStorage.getItem('mySocketId', '');
        this.myId = JSON.parse(localStorage.getItem('user', ''))['_id'];

        this.peer = null

        this.mediaRecorder = null;
        this.mediaChunks = [];

        // Bind function
        this.makeid = this.makeid.bind(this);
        this.startCapture = this.startCapture.bind(this);
        this.stopCapture = this.stopCapture.bind(this);
        this.answerCall = this.answerCall.bind(this);
        this.endCall = this.endCall.bind(this);
        this.goToDashboard = this.goToDashboard.bind(this);
        this.openNotesDialog = this.openNotesDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.cleanup = this.cleanup.bind(this);
    }

    state = {
        open: false,
        stream: null,
        callNotes: '',
        answering: false,
    };

    componentWillUnmount = () => {
        this.cleanup()
    };

    componentDidMount = () => {
        this.props.socket.off('callEnded')

        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((stream) => {
                this.setState({ stream })

                this.answerCall()
            })
    };

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    startCapture = (userStream) => {
        var arrayOfStreams = [this.state.stream, userStream];

        let mixer = new window.MultiStreamsMixer(arrayOfStreams);
        mixer.frameInterval = 1;
        mixer.startDrawingFrames();

        let mixed = mixer.getMixedStream();
        this.mediaRecorder = new MediaRecorder(mixed);

        let thisRef = this
        this.mediaRecorder.ondataavailable = function (event) {
            thisRef.mediaChunks.push(event.data);
            let mediaBlob = new Blob(thisRef.mediaChunks, { type: "video/webm" });
            thisRef.props.redux.dispatch(uploadRecording({ blob: mediaBlob, callId: thisRef.callId, fileName: '_' + thisRef.makeid(32) + '.webm' }))
        };

        this.mediaRecorder.start();
    }

    stopCapture = () => {
        if (this.mediaRecorder != null) {
            if (this.mediaRecorder.state === "recording") {
                this.mediaRecorder.stop();
            }
        }
    }

    answerCall = () => {
        if (this.state.answering === false) {
            console.log(this.state.stream)
            this.peer = new Peerlib(this.state.stream);

            this.setState({ answering: true })

            // on peer signal
            this.peer.onSignal((data) => {
                this.props.socket.emit("answerCall", {
                    signal: data,
                    to: this.from,
                    me: this.mySocketId,
                    callId: this.callId,
                    myId: this.myId
                });
            });

            // peer on stream
            this.peer.onStream((stream) => {
                this.userVideoRef.current.srcObject = stream;

                this.startCapture(stream)
            });

            this.peer.peer.signal(this.signal);
            this.myVideoRef.current.srcObject = this.state.stream;
            this.myVideoRef.current.muted = true;

            // on call ended
            this.props.socket.on("callEnded", (data) => {
                this.stopCapture()

                // peer.peer.destroy()
                if (this.state.stream) {
                    this.state.stream.getTracks().forEach(function (track) {
                        if (track.readyState === 'live') {
                            track.stop();
                        }
                    });
                }

                this.setState({ stream: null })

                this.openNotesDialog()
            });
        }
    };

    endCall = () => {
        this.props.socket.emit("endCall", {
            me: this.mySocketId,
            to: this.from,
            callId: this.callId
        });
    }

    goToDashboard = () => {
        this.props.router.navigate('/dashboard')
    }

    openNotesDialog = () => {
        this.setState({ open: true })
    };

    handleClose = () => {
        this.setState({ open: false })
    };

    cleanup = () => {
        if (this.state.stream) {
            this.state.stream.getTracks().forEach(function (track) {
                if (track.readyState === 'live') {
                    track.stop();
                }
            });
        }

        this.props.socket.emit("endCall", {
            me: this.mySocketId,
            to: this.from,
            callId: this.callId
        });

        if (this.from === null || this.from === undefined) {
            window.open('/dashboard/calls', '_self')
        }
    }

    render() {
        return (
            <>
                <Box sx={{
                    backgroundColor: '#faffff',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '100%',
                    minHeight: '90vh',
                    maxHeight: '90vh',
                }}>
                    <Box p={3} sx={{ minWidth: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant='h3'>
                            Help Desk
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            Ongoing video call
                        </Typography>
                    </Box>

                    <Stack direction='row' sx={{ minWidth: '100%', flexGrow: 1 }}>
                        <video
                            autoPlay
                            playsInline
                            ref={this.myVideoRef}
                            style={{
                                maxWidth: "50%",
                                minWidth: "50%",
                            }}
                        />

                        <video
                            autoPlay
                            playsInline
                            ref={this.userVideoRef}
                            style={{
                                maxWidth: "50%",
                                minWidth: "50%",
                            }}
                        />
                    </Stack>

                    <Box textAlign='center' sx={{ minWidth: '100%', position: 'fixed', bottom: '0px', backgroundColor: '#f5f5f5' }} p={3}>
                        <Fab onClick={() => { this.endCall() }} color="error" >
                            <CallIcon sx={{ color: 'white', fontSize: '2rem' }} />
                        </Fab>
                    </Box>
                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '50px',
                        right: '50px'
                    }}>
                    <Card sx={{ padding: 2 }}>
                        <Box mb={2}>
                            <Typography variant='h6'>Logs</Typography>
                            <Typography variant='caption' color='text.secondary'>{this.sessionId}</Typography>
                        </Box>

                        {
                            this.sessionLogs?.map((log, index) => {
                                const time = formatTime(new Date(log.time))

                                return <Box key={`sessionLog${index}`} sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#ebebeb', borderRadius: 1 }} p={1} mb={1}>
                                    <Box sx={{ minHeight: '0.6rem', minWidth: '0.6rem', maxHeight: '0.6rem', maxWidth: '0.6rem', backgroundColor: 'primary.main', borderRadius: 20, marginRight: 1 }}></Box>
                                    <Typography variant='body2' sx={{ flexGrow: 1, marginRight: 2 }}>{log.action}</Typography>
                                    <Typography variant='caption' color='text.secondary'>{time}</Typography>
                                </Box>
                            })
                        }

                    </Card>
                </Box>

                <Dialog
                    open={this.state.open}
                    fullWidth
                    maxWidth='lg'
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Enter call notes (optional)"}
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            {
                                <SunEditor
                                    height="50vh"
                                    defaultValue={''}
                                    value={''}
                                    ref={this.state.notesEditorRef}
                                    setDefaultStyle="font-family: Public Sans, sans-serif; font-size: 16px;"
                                    onChange={(value) => { this.setState({ callNotes: value }) }}
                                    setOptions={{
                                        buttonList: [
                                            ['undo', 'redo'],
                                            ['fontSize', 'formatBlock'],
                                            ['paragraphStyle', 'blockquote'],
                                            ['bold', 'underline', 'italic'],
                                            ['fontColor', 'hiliteColor'],
                                            ['image']
                                        ]
                                    }}
                                />
                            }
                        </Box>

                        <Box mt={2}>
                            <Button
                                size='large'
                                onClick={() => { this.handleClose(); this.props.redux.dispatch(saveVideoCallNotes({ notes: this.state.callNotes, callId: this.callId })); this.goToDashboard(); }}
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}

export default withRouter(VideoCall);
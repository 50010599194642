import Peer from "simple-peer";

// Free STUN servers
// stun.l.google.com:19302
// stun1.l.google.com:19302
// stun2.l.google.com:19302
// stun3.l.google.com:19302
// stun4.l.google.com:19302
// stun01.sipphone.com
// stun.ekiga.net
// stun.fwdnet.net
// stun.ideasip.com
// stun.iptel.org
// stun.rixtelecom.se
// stun.schlund.de
// stunserver.org
// stun.softjoys.com
// stun.voiparound.com
// stun.voipbuster.com
// stun.voipstunt.com
// stun.voxgratia.org
// stun.xten.com

// TURN server
// turn:turn01.hubl.in?transport=udp
// turn:turn02.hubl.in?transport=tcp

// {
//     url: 'turn:numb.viagenie.ca',
//     credential: 'muazkh',
//     username: 'webrtc@live.com'
// },
// {
//     url: 'turn:192.158.29.39:3478?transport=udp',
//     credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
//     username: '28224511:1379330808'
// },
// {
//     url: 'turn:192.158.29.39:3478?transport=tcp',
//     credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
//     username: '28224511:1379330808'
// },
// {
//     url: 'turn:turn.bistri.com:80',
//     credential: 'homeo',
//     username: 'homeo'
//  },
//  {
//     url: 'turn:turn.anyfirewall.com:443?transport=tcp',
//     credential: 'webrtc',
//     username: 'webrtc'
// }

export class Peerlib {
  constructor(stream) {
    this.peer = new window.SimplePeer({
      initiator: false,
      trickle: false,
      stream: stream,
      config: {
        iceServers: [
          { urls: "stun:stun.pocsample.in:5349" },
          {
            urls: "turn:turn.pocsample.in:5349",
            username: "root",
            credential: "9876543210",
          },
        ],
      },
    });
  }

  onSignal(callback) {
    this.peer.on("signal", (data) => callback(data));
    return;
  }

  onStream(callback) {
    this.peer.on("stream", (stream) => callback(stream));
    return;
  }

  onCallAccepted(callback) {
    this.peer.on("callAccepted", (signal) => callback(signal));
    return;
  }
}
